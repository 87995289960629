h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'TS Block';
  font-weight: 700;
}

h1 {
  font-size: 3rem;
  @media (max-width: 1440px) {
    font-size: 2.5rem;
  }
  @media (max-width: 768px) {
    font-size: 2rem;
  }
}
h2 {
  font-size: 2.5rem;
  @media (max-width: 1440px) {
    font-size: 2.25rem;
  }
  @media (max-width: 768px) {
    font-size: 1.75rem;
  }
}
h3 {
  font-size: 2rem;
  @media (max-width: 1440px) {
    font-size: 1.75rem;
  }
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
}
h4 {
  font-size: 1.5rem;
  @media (max-width: 1440px) {
    font-size: 1.35rem;
  }
  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
}
h5 {
  font-size: 1rem;
}

@font-face {
  font-family: 'TS Block';
  font-weight: 700;
  src: local('TS-Block-Bold'),
    url(../assets/fonts/TS-Block-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'DarkerGrotesque';
  font-weight: 400;
  src: local('DarkerGrotesque-Regular'),
    url(../assets/fonts/DarkerGrotesque-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'DarkerGrotesque';
  font-weight: 600;
  src: local('DarkerGrotesque-Medium'),
    url(../assets/fonts/DarkerGrotesque-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'DarkerGrotesque';
  font-weight: 700;
  src: local('DarkerGrotesque-Bold'),
    url(../assets/fonts/DarkerGrotesque-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Chonburi';
  src: local('Chonburi-Regular'),
    url(../assets/fonts/Chonburi-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Fraunces';
  src: local('Fraunces-Regular'),
    url(../assets/fonts/Fraunces-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: local('Inter-Semibold'),
    url(../assets/fonts/Inter-Semibold.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: local('Inter-Regular'),
    url(../assets/fonts/Inter-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: local('Inter-Medium'),
    url(../assets/fonts/Inter-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Gelion';
  font-weight: 700;
  src: local('Gelion-Bold'),
    url(../assets/fonts/Gelion-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Kozuka Mincho Pr6N';
  font-weight: 700;
  src: local('Kozuka-Mincho-Pr6N-Bold'),
    url(../assets/fonts/Kozuka-Mincho-Pr6N-Bold.otf) format('opentype');
}
